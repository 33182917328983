.background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
   }
   
   .background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/bg.jpg');
    background-size: cover;
    background-position: center;
    opacity: 1;
    transition: opacity 0.3s ease;
   }
   